import React, { useState } from 'react';

const DateInput = (props) => {
    const [selectedDate, setSelectedDate] = useState('');

    // Function to format a date to YYYY-MM-DD
    const getFormattedDate = (date) => {
        const offset = date.getTimezoneOffset();
        const localDate = new Date(date.getTime() - offset * 60 * 1000);
        const response = localDate.toISOString().split('T')[0];

        return response;
    };

    // Get today's date and set the time to midnight
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Get tomorrow's date by adding one day to today
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 2);

    // Set the minimum selectable date to the day after tomorrow
    const minDate = getFormattedDate(tomorrow);

    // Function to check if a date is invalid (weekends, today, or tomorrow)
    const isInvalidDate = (date) => {
        const selected = (new Date(`${date}T00:00:00`));
        selected.setHours(0, 0, 0, 0); // Set time to midnight for accurate comparison

        const dayOfWeek = selected.getDay(); // 0 = Sunday, 6 = Saturday
        debugger;
        return (
            dayOfWeek === 0 || // Sunday
            dayOfWeek === 6 // Saturday
            // selected.getTime() === today.getTime() || // Today
            // selected.getTime() === tomorrow.getTime() // Tomorrow
        );
    };

    // Handle date change event
    const handleChange = (event) => {
        const newDate = event.target.value;
        debugger;
        if (isInvalidDate(newDate)) {
            alert('Finais de semana não podem ser selecionados. Por favor, selecione outra data.');
            setSelectedDate('');
            return;
        }

        setSelectedDate(newDate);
    };

    return (
        <input
            {...props}
            type="date"
            placeholder="__/__/____"
            value={selectedDate}
            min={minDate}
            onChange={handleChange}
            className='form-control'
        />
    );
};

export default DateInput;
