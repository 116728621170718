import React from "react";
import "./styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

const MultiStepProgressBar = ({ page, onPageNumberClick }) => {
    var stepPercentage = 0;

    if (page === "pageone") {
        stepPercentage = 16;
    } else if (page === "pagetwo") {
        stepPercentage = 49.5;
    } else if (page === "pagethree") {
        stepPercentage = 82.5;
    } else if (page === "pagefour") {
        stepPercentage = 100;
    } else {
        stepPercentage = 0;
    }

    return (
        <ProgressBar percent={stepPercentage}>
            <Step>
                {({ accomplished, index }) => (
                    <>
                        <div
                            className={`indexedStep ${accomplished ? "accomplished" : ""}`}
                            onClick={() => onPageNumberClick("1")}
                        >
                            {index + 1}
                            {accomplished && page === 'pageone' && (
                                <svg className="right-chevron" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.59 16.59L13.17 12L8.59 7.41L10 6L16 12L10 18L8.59 16.59Z" fill="#FFF" />
                                </svg>
                            )}
                        </div>
                        <div className="step-label">
                            <div className="step-label-text">
                                CAMINHÃO CHEGOU
                            </div>
                        </div>
                    </>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <>
                        <div
                            className={`indexedStep ${accomplished ? "accomplished" : ""}`}
                            onClick={() => onPageNumberClick("2")}
                        >
                            {index + 1}
                            {accomplished && page === 'pagetwo' && (
                                <svg className="right-chevron" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.59 16.59L13.17 12L8.59 7.41L10 6L16 12L10 18L8.59 16.59Z" fill="#FFF" />
                                </svg>
                            )}
                        </div>
                        <div className="step-label">
                            <div className="step-label-text">
                                OPERAÇÃO INICIADA
                            </div>
                        </div>
                    </>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <>
                        <div
                            className={`indexedStep ${accomplished ? "accomplished" : ""}`}
                            onClick={() => onPageNumberClick("3")}
                        >
                            {index + 1}
                            {accomplished && page === 'pagethree' && (
                                <svg className="right-chevron" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.59 16.59L13.17 12L8.59 7.41L10 6L16 12L10 18L8.59 16.59Z" fill="#FFF" />
                                </svg>
                            )}
                        </div>
                        <div className="step-label">
                            <div className="step-label-text">
                                OPERAÇÃO CONCLUÍDA
                            </div>
                        </div>
                    </>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <>
                        <div
                            className={`indexedStep ${accomplished ? "accomplished" : ""}`}
                            onClick={() => onPageNumberClick("4")}
                        >
                            {index + 1}
                            {accomplished && page === 'pagefour' && (
                                <svg className="right-chevron" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.59 16.59L13.17 12L8.59 7.41L10 6L16 12L10 18L8.59 16.59Z" fill="#FFF" />
                                </svg>
                            )}
                        </div>
                        <div className="step-label">
                            <div className="step-label-text">
                                CAMINHÃO SAIU
                            </div>
                        </div>
                    </>
                )}
            </Step>
        </ProgressBar>
    );
};

export default MultiStepProgressBar;
